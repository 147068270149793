import React from 'react';
import Image from "../elements/image";
import { FiUser } from "react-icons/fi";
import Layout from "../components/layout";
import { graphql } from "gatsby"
import Seo from '../components/seo';
import YoutubeEmbed from '../elements/YoutubeEmbed';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProjectDetails = ({data}) => {
    const projectData = data.directus.portfolio_by_id;
    const projectImage = data.directus.portfolio_by_id.images;
    const youtubeId = projectData.youtube_code;
    return (
      <>
        <Seo title={projectData.title} />
        <Layout>
            <div className="rn-project-details-area rn-section-gap bg-color-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <div className="portfolio-content">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <div className="content-left">
                                                <div className="page-top">
                                                    <h1 className="title_holder">{projectData.title}</h1>
                                                </div>
                                                <h3 className="mt--20">Details</h3>
                                                <ul className="list_holder">
                                                    <li><span className="icon"><FiUser />Role:</span><span className="projectinfo">{projectData.role}</span></li>
                                                </ul>
                                                <p className="mt--20" dangerouslySetInnerHTML={{ __html: projectData.content }}></p>
                                                {youtubeId ? 
                                                  <YoutubeEmbed embedId={youtubeId} />
                                                  : 
                                                  null
                                                }
                                                
                                                <div className="thumbnail mt--90 mt_md--40 mt_sm--40">
                                                    <Image fluid={projectData.featured_image.imageFile.childImageSharp.fluid} />
                                                </div>

                                                <div className="image-group">
                                                  {projectImage ? 
                                                    projectImage.map((data, index) => (
                                                      <div className="single-image mt--30" key={index}>
                                                          <GatsbyImage className="about-images" image={getImage(data.directus_files_id.imageFile.childImageSharp.gatsbyImageData)}  />
                                                          
                                                      </div>
                                                    ))
                                                  :
                                                    null
                                                  }
                                                </div> 
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
      </>
    )
}
//<Img fluid={data.directus_files_id.imageFile.childImageSharp.fluid} />
export const query = graphql `
    query($id: ID!) {
        directus {
          portfolio_by_id(id: $id) {
            title
            slug
            youtube_code
            role
            id
            content
            featured_image {
              imageFile{
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
              id
            }
            images {
              directus_files_id {
                imageFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                id
              }
            }
          }    
        }
    }
`;
export default ProjectDetails;